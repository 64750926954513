<template>
    <div class="login">
        <label for="uname"><b>E-mail</b></label>
        <input type="text" placeholder="Enter E-mail" name="uname" required v-model="email">

        <label for="psw"><b>Password</b></label>
        <input type="password" placeholder="Enter Password" name="psw" required v-model="password">

        <AlertBar v-if="error" :type="`error`" :text="error"></AlertBar>
        <button type="submit" @click="login()">Login</button>
    </div>
</template>

<script>
import AlertBar from '@/components/AlertBar.vue';

export default {
    name: 'LoginView',
    components: {
        AlertBar,
    },
    data() {
        return {
            email: "",
            password: "",
            error: null
        };
    },
    methods: {
        login() {
            fetch(process.env.VUE_APP_API_URL + '/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status) {
                        this.error = null;
                        localStorage.setItem('token', data.token);
                        this.$router.push('/scanner');
                    } else {
                        this.error = this.$t(data.message);
                        Object.entries(data.errors).map((error) => {
                            this.error += "<br />" + error;
                        });
                        console.log('invalid credentials');
                    }
                });
        }
    },
}
</script>

<style scoped>
input[type=text],
input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

button {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

.login {
    padding: 16px;
}
</style>