<template>
  <div v-if="user">
    <p class="error">{{ error }}</p>
    <p class="user">User: <b>{{ user.name }}</b></p>
    <p class="decode-result">Last result: <b>{{ result }}</b></p>

    <qrcode-stream @decode="onDecode" @init="onInit" />
    <div id="ticketStatus" class="modal" :style="`background-color: ` + statuscolor">
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: { QrcodeStream },
  data() {
    return {
      result: '',
      error: '',
      user: null,
      statuscolor: 'red'
    }
  },
  created: function () {
    fetch(process.env.VUE_APP_API_URL + '/auth/whoami', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.user = data;
      });
  },

  methods: {
    showTicketStatus(isValid) {
      var modal = document.getElementById("ticketStatus");
      if (isValid) {
        this.statuscolor = "green";
      } else {
        this.statuscolor = "red";
      }
      modal.style.display = "block";
      setTimeout(function () {
        modal.style.display = "none";
      }, 5000);
    },
    onDecode(result) {
      this.result = result;
      fetch(process.env.VUE_APP_API_URL + '/scanner/ticket/verify', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code: result
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.showTicketStatus(data.status);
        });
    },

    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
</style>